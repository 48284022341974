import * as React from 'react';

import { HorizontalYellowLineShape } from '../../Atoms/Icons/Icons';

import './ImplantationSingleCard.scss';

const ImplantationSingleCard = ({ title, adresse, tel1, tel2, email, website }) => {
  return (
    <div className="implantation_single_card_component">

      <div className="text">
        <h3 className="title">
          <span>
            {title.length > 50 ? `${title.substring(0, 47)}...` : title}
          </span>
          <HorizontalYellowLineShape className="line" />
        </h3>
        <p className="adresse">{adresse}</p>
        <p className="phone">
          {tel1 && 'Tel. ' + tel1}
          {tel2 && '<br />Tel. ' + tel2}
        </p>
        <p className="email">{email || ''}</p>
        <p className="web">{website || ''}</p>
      </div>
    </div>
  );
};

ImplantationSingleCard.default = {
  title: 'Default Title',
  adresse: 'Default Adresse',
  details: 'Tel. 01 00 00 00 00'
};

export default ImplantationSingleCard;
