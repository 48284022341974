import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import Dropdown from '../../../components/Dropdown/Dropdown';
import ImplantationSingleCard from '../../../components/ImplantationSingleCard/ImplantationSingleCard';
import PageBannerMap from '../../../components/PageBannerMap/PageBannerMap';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

import './styles.scss';

let classNames = require('classnames');

const ImplantationPage = ({ data }) => {
	const intl = useIntl();
	const allSiegeData = data?.allSiegeData.edges;
	const implantationData = data?.implantationData.edges[0].node;

	let siegeListArr = [];
	let dropDownArr = [];
	let counter = 1;

	const [siegeList, setSiegeList] = useState([]);
	const [dropDownList, setDropDownList] = useState([]);
	const [selectedContinent, setSelectedContinent] = useState('');
        const metaTags =  implantationData.metatag;
        let metaTitle = '';
        let metaDesc = '';

        metaTags.forEach((meta) => {
          if(meta.attributes.name === 'title'){
              metaTitle = meta.attributes.content;
          }
          if(meta.attributes.name === 'description'){
              metaDesc = meta.attributes.content;
          }
        });
	const addSiege = (siege) => {
		let continentAvail = siegeListArr.filter(
			(a) => a.continent === siege.node?.field_continent
		);
		let nodeObj = siege.node;
		Object.assign(nodeObj, { visuel: data.file?.publicURL });

		if (continentAvail.length > 0) {
			siegeListArr
				.filter((a) => a.continent === nodeObj?.field_continent)[0]
				?.others.push(nodeObj);
		} else {
			const continent = intl.formatMessage({
				id: `implantation.continent.${nodeObj?.field_continent}`,
			});
			siegeListArr.push({
				continent: nodeObj?.field_continent,
				continentTrad: continent,
				others: [nodeObj],
			});
		}
		siegeListArr.sort((a, b) => a.continent.localeCompare(b.continent));
		setSiegeList(siegeListArr);
	};

	useEffect(() => {
		allSiegeData.forEach((siege) => {
			addSiege(siege);
		});

		dropDownArr.push({
			id: 0,
			label: intl.formatMessage({ id: 'implantation.select_continent' }),
		});

		siegeListArr.forEach(
			(siege) =>
				siege.continent != null &&
				dropDownArr.push({
					id: counter++,
					label: siege.continentTrad,
				})
		);

		setDropDownList(dropDownArr);
	}, []);

	const onChangeContinentDropdown = (continent_selected) => {
		setSelectedContinent(continent_selected);
	};

	const slider_setting_cards = {
		className: 'implantation_cards',
		centerMode: true,
		infinite: false,
		centerPadding: '20px',
		speed: 300,
		initialSlide: 0,
		arrows: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<Layout>
			<Seo
                            title={implantationData?.field_metatag?.title ? implantationData?.field_metatag?.title : metaTitle}
                            description={implantationData?.field_metatag?.description ? implantationData?.field_metatag?.description : metaDesc}
			/>
			<div className={classNames('page_template', 'implantations_page')}>
				<PageBannerMap
					iframeURL={'https://colas-implantations-monde.latitude-cartagene.com/?lang=' + implantationData.path?.langcode}
					breadcrumbData={{
						parentPage: { title: 'Groupe', url: '' },
						currentPage: {
							title: implantationData.title,
							url:
								'/' +
								implantationData.path?.langcode +
								implantationData.path?.alias,
						},
						locale: implantationData.langcode,
					}}
				>
					<div className='wrapper_page'>
						{
							implantationData.field_link?.url ?
								<div className="title_with_btn">
									<TitlePage color='color_white' title={implantationData.title} />
									<Button
										primary={true}
										label={implantationData.field_link?.title}
										link={implantationData.field_link?.url}
										arial-label={intl.formatMessage({ id: 'common.open' }) + ' ' + intl.formatMessage({ id: 'implantation.btn_french_map' })}
									/>
								</div> :
								<TitlePage color='color_white' title={implantationData.title} />
						}
						{implantationData.relationships?.field_blocs?.map((block, i) => {
							if (block.__typename === 'block_content__block_chiffres_clefs') {
								return (
									<div key={i}>
										<div className='col_2'>
											<div className='col graph'>
												<GraphRH
													text={
														block.relationships?.field_values[0]?.field_legend
															?.processed
													}
													jobs={
														block.relationships?.field_values[0]?.field_chiffre
													}
													textColor='color_white'
												/>
											</div>
											<div
												className='col text'
												dangerouslySetInnerHTML={{
													__html: block?.body?.processed,
												}}
											></div>
										</div>
									</div>
								);
							}
						})}
					</div>
				</PageBannerMap>
				<section
					className={classNames('section_content', 'section_region_list')}
				>
					<div className='dropdown_container wrapper_page'>
						<Dropdown
							onChange={onChangeContinentDropdown}
							selected={selectedContinent}
							list={dropDownList}
						/>
					</div>
					{siegeList.map((siege, i) => (
						<div
							key={i}
							className={classNames(
								'continent_data',
								selectedContinent?.id === 0 || selectedContinent === ''
									? 'show'
									: selectedContinent?.label === siege.continentTrad
										? 'show'
										: 'hide'
							)}
							aria-live='polite'
						>
							<div className='wrapper_page'>
								<TitleSection
									title={siege.continentTrad}
									// description={
									// 	siege.others?.length +
									// 	' ' +
									// 	intl.formatMessage({
									// 		id: `implantation.nb_locations.${siege.others?.length > 1 ? 'plural' : 'singular'
									// 			}`,
									// 	})
									// }
									type='line1'
								/>
								<div className='listCardsDesktop'>
									{siege.others?.map((card, index) => (
										<ImplantationSingleCard
											key={index}
											title={card?.title}
											adresse={card?.field_address || null}
											tel1={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[0]
													: card?.field_telephone
											}
											tel2={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[1]
													: null
											}
											email={card.field_email}
											website={card.field_website}
										/>
									))}
								</div>
							</div>
							<div className='listCardsMobile'>
								<SliderComponent settings={slider_setting_cards}>
									{siege.others?.map((card, index) => (
										<ImplantationSingleCard
											key={index}
											title={card.title}
											adresse={card?.field_address || null}
											tel1={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[0]
													: card?.field_telephone
											}
											tel2={
												Array.isArray(card?.field_telephone)
													? card?.field_telephone[1]
													: null
											}
											email={card.field_email}
											website={card.field_website}
										/>
									))}
								</SliderComponent>
							</div>
						</div>
					))}
				</section>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query implatationTemplateQuery($locale: String!, $slug: String!) {
		implantationData: allNodePage(
			filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
		) {
			edges {
				node {
					title
					path {
						alias
						langcode
					}
					field_metatag {
						description
						title
					}
                                        metatag {
                                                attributes {
                                                        content
                                                        name
                                                }
                                      }
					field_type
					field_link {
						title
						url
					}
					langcode
					body {
						processed
					}
					relationships {
						field_blocs {
							__typename
							... on Node {
								... on block_content__block_chiffres_clefs {
									id
									body {
										processed
									}
									field_title {
										processed
									}
									relationships {
										field_values {
											field_chiffre
											field_legend {
												processed
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		allSiteData: allNodeNosImplantations(
			filter: { langcode: { eq: $locale } }
		) {
			edges {
				node {
					field_address
					field_city
					field_code_postale
					field_email
					field_latitude
					field_longitude
					field_telephone
					field_type_etablissement
					field_continent
					field_website
					title
					path {
						alias
						langcode
					}
					langcode
					drupal_id
				}
			}
		}

		allSiegeData: allNodeNosImplantations(
			filter: {
				langcode: { eq: $locale }
				field_type_etablissement: { eq: "Siège" }
			}
			sort: {order: ASC, fields: title}
		) {
			edges {
				node {
					field_address
					field_city
					field_code_postale
					field_email
					field_latitude
					field_longitude
					field_telephone
					field_type_etablissement
					field_continent
					field_website
					title
					path {
						alias
						langcode
					}
					langcode
					drupal_id
				}
			}
		}
	}
`;

export default ImplantationPage;
